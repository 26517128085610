import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Container, Form, FormCheck, Button} from "react-bootstrap";

const Ftr = styled(Container)`
  min-height: 30vh;
  padding-top: 60px;
  // display: flex;
  // // flex-direction: column;
  // justify-content: flex-center;
  // align-items: center;
  // background-color: rgba(10,42,73,0.5);
  background-color: rgba(120,120,120,0.5);
  clip-path: polygon(0 5vh, 100% 0, 100% 100%, 0 100%);
  `;

const FooterDiv = styled.div`
    margin: 0 2vw;
  `;

const FooterH4 = styled.h4`
    line-height: 2em;
  `;

const FooterP = styled.p`
    margin-left: 1vw;
    line-height: 0.5em;
  `;

const ContactData = () => {
  return(
      <FooterDiv>
        <FooterH4>Keep in touch:</FooterH4>
        <FooterP><span>Phone: <a href="tel:+48518258868">+48 518 258 868</a></span></FooterP>
        <FooterP><span>Email:  <a href="mailto:contact@carpendev.com">contact@carpendev.com</a></span></FooterP>
      </FooterDiv>
  );
}

const SocialMedia = () => {
  const SocialMediaLink = styled.a``;
  const SocialMediaImage = styled.img`
    width: auto;
    height: 100%;
   `;
  const SocialMediasContainer = styled.div` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;  
  `;
  const SocialContainer = styled.div`
    width: 48px;
    margin: 0 8px;
  `;

  const Socials = (items) => {
      return (
        <div>

        </div>
      );
  }

    const SocialMedia = ({imgSrc, imgAlt, href}) => {
        return (
            <SocialContainer>
                <SocialMediaLink href={href} target={"_blank"}>
                    <SocialMediaImage src={imgSrc} alt={imgAlt}/>
                </SocialMediaLink>
            </SocialContainer>
        );
    }

    return(
        <FooterDiv>
            <FooterH4>Follow Us: </FooterH4>
            <SocialMediasContainer>
                {socialMedia.map(item => (
                    <SocialMedia
                        imgSrc={item.imgSrc}
                        imgAlt={item.imgAlt}
                        href={item.href}
                    />
                ))}
            </SocialMediasContainer>
        </FooterDiv>
    );
}

const ContactForm = () => {
  return(
      <Form id='contact-us' action="https://formspree.io/f/xyybpoer" method="POST">
        <Form.Row>
          <Col sm={12} md={8} lg={6}>
            <FormLabel>Name:</FormLabel>
            <Form.Control name="name" placeholder="How to address you?" />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={8} lg={6}>
            <FormLabel>E-mail:</FormLabel>
            <Form.Control required name="email" placeholder="Please enter your email" />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={8} lg={8}>
            <FormLabel>Message:</FormLabel>
            <Form.Control required name="message" as='textarea' placeholder="Ask the question. We will contact you at the given email address" />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <EmptySpace/>
            <FormCheck>
              <FormCheck.Input required type="checkbox" name="gdprAgreement"/>
              <FormClause>
                This form collects your name and email address so that we can communicate with you to answer the questions and provide assistance.
                Please check out <A href="https://formspree.io/legal/privacy-policy">Formspree Privacy Policy</A> to see how your submitted data is protected and managed.
              </FormClause>
            </FormCheck>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button type="submit">Send</Button>
          </Col>
        </Form.Row>
      </Form>
  );
}

const A = styled.a`
  font-size: 0.9em;
`;

const FormClause = styled(FormCheck.Label)`
  font-size: 0.7em;
`;

const FormLabel = styled(Form.Label)`
  line-height: 0.7em;
`;

const EmptySpace = styled.div`
  margin-bottom: 1em;
`
const Footer = () => {

  return (<Ftr className="align-items-end pb-5  " fluid>
    <Row>
      <Col md={12} lg={6}>
          <Row>
           <ContactData/>
          </Row>
          <Row>
            <SocialMedia/>
          </Row>
      </Col>
      <Col md={12} lg={6}>
        <ContactForm/>
      </Col>
    </Row>
  </Ftr>);
}

const socialMedia = [
    {
        "imgSrc": "/images/logo/social/LinkedIN_black.svg",
        "imgAlt": "social",
        "href": "https://www.linkedin.com/company/carpendev"
    },
    {
        "imgSrc": "/images/logo/social/Facebook_black.svg",
        "imgAlt": "social",
        "href": "#"
    },
    {
        "imgSrc": "/images/logo/social/Instagram_black.svg",
        "imgAlt": "social",
        "href": "https://www.instagram.com/carpendev/"
    },
    {
        "imgSrc": "/images/logo/social/Blogger_black.svg",
        "imgAlt": "social",
        "href": "https://blog.carpendev.com/"
    }
];

export default Footer;